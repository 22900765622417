import { Backdrop, Box, CircularProgress, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useParams, useSearchParams } from 'react-router-dom';
import { useMain } from '../../contexts/MainContext';
import { saveAtendimento } from '../../services/atendimento';
import { toast } from 'react-toastify';
import { setExternalApresentation } from '../../config/isExternalApresentation';

// import { Container } from './styles';

function IntegracaoMv() {
    const { usuario, globalIsRecording, atendimentos, carregaAtendimentos, carregaUsuario } = useMain();
    const navegation = useNavigate();
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const { idRegistro } = useParams();
    const nomePaciente = searchParams.get('nome');

    useEffect(() => {
        async function init() {
            try {
                setExternalApresentation();
                await novoAtendimento();
                setLoading(false);
            } catch (error) {
                console.error(error);
                toast.error('Erro ao carregar dados');
            }
        }
        init();
    }, []);

    async function novoAtendimento() {
        try {
            if (usuario?.corporativo?.saldo != null) {
                if (usuario?.corporativo?.saldo == 0) {
                    toast.warning('Você não possui saldo suficiente para criar uma nova consulta');
                    toast.info('Para continuar utilizando o serviço, adquira mais saldo.');
                    return;
                }
            } else {
                if (usuario?.saldo == 0) {
                    toast.warning('Você não possui saldo suficiente para criar uma nova consulta');
                    toast.info('Para continuar utilizando o serviço, adquira mais saldo.');
                    return;
                }
            }
            if (globalIsRecording) throw new Error('Você não pode criar uma nova consulta enquanto estiver gravando');
            setLoading(true);
            const atendimentoCriado = await saveAtendimento(
                `Consulta Nº ${atendimentos.length + 1}`,
                'Descrição da nova consulta',
            );

            navegation(`/main/${atendimentoCriado.id}?mv=${idRegistro}`, {
                state: {
                    mv: {
                        idRegistro,
                        nomePaciente,
                    },
                },
            });
        } catch (error) {
            toast.error('Erro ao criar nova consulta');
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Box
            height={'100%'}
            width={'100%'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            flexDirection={'column'}
        >
            <Typography variant="h4">Integração R1 | MV</Typography>
            <Typography variant="h5">Aguarde... {nomePaciente}</Typography>
            {nomePaciente && <Typography variant="body1">Paciente: {nomePaciente}</Typography>}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={() => setLoading(false)}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );
}

export default IntegracaoMv;
