import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: ${(props) => (props.isSmallScreen ? 'column' : 'row')};
    align-items: center;
    justify-content: center;
    background-color: #f4f4f4;
    overflow: hidden;
`;
