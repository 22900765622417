import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1300;
    height: 100%;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.9);
    backdrop-filter: blur(10px);
    color: #ffffff;
`;

export const TituloResumo = styled.h2`
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 0px;
    margin-top: 0px;
    text-align: center;
    border-bottom: 1px solid #7474744d;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
`;

export const ContainerAssistentes = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100vw;

    &::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #262626;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #1976d250;
        border-radius: 5px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #1976d250;
    }
`;

export const AssistenteStyle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    font-family: 'Roboto', sans-serif;
    max-width: 800px;

    > h3 {
        padding: 10px;
        margin-bottom: 5px;
        font-size: 20px;
        font-style: italic;
        text-transform: capitalize;
    }

    > article {
        padding: 0px 5px 10px 40px;
        line-height: 1.5;
        font-weight: 400;
        font-size: 16px;
        word-spacing: 1px;
        letter-spacing: 0.5px;
        text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.1);

        > h1 {
            font-size: 18px;
        }

        > h2 {
            font-size: 16px;
        }

        > p {
            font-size: 14px;
        }
    }

    > textarea {
        font-family: 'Roboto', sans-serif;
        width: 100%;
        background-color: transparent;
        border: none;
        color: #ffffff;
        resize: none;

        padding: 0px 5px 10px 40px;
        line-height: 1.5;
        font-weight: 400;
        font-size: 16px;
        word-spacing: 1px;
        letter-spacing: 0.5px;
        text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.1);
    }
`;
