import axios from 'axios';
import constantes from '../config/constantes';

export async function login(email, senha) {
    const response = await axios.post(
        `${constantes.baseUrl}Auth/login`,
        {
            Username: email,
            Password: senha,
        },
        {
            headers: {
                'X-Captcha': 'ABC123',
            },
        },
    );
    return response.data.response;
}
export async function loginAdmCorp(email, senha) {
    const response = await axios.post(
        `${constantes.baseUrl}Auth/LoginAdmCorp`,
        {
            Username: email,
            Password: senha,
        },
        {
            headers: {
                'X-Captcha': 'ABC123',
            },
        },
    );
    return response.data.response;
}

export async function register(Username, Email, Password, PhoneNumber) {
    const response = await axios.post(
        `${constantes.baseUrl}Auth/register`,
        { Username, Email, Password, PhoneNumber },
        {
            headers: {
                'X-Captcha': 'ABC123',
            },
        },
    );
    return response.data.response;
}

export async function gerarLinkEsqueciSenha(email) {
    const response = await axios.post(
        `${constantes.baseUrl}Auth/gerarLinkEsqueciSenha`,
        { Email: email },
        {
            headers: {
                'X-Captcha': 'ABC123',
            },
        },
    );
    return response.data.response;
}

export async function trocarSenha(token, senha, email) {
    const response = await axios.post(
        `${constantes.baseUrl}Auth/trocarSenha`,
        { Token: token, Password: senha, Email: email },
        {
            headers: {
                'X-Captcha': 'ABC123',
            },
        },
    );
    return response.data.response;
}
