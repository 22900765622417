import { createBrowserRouter } from 'react-router-dom';
import Main from './pages/main/main';
import Login from './pages/login/login';
import Home from './pages/home/Home';
import Conteudo from './pages/main/conteudo/Conteudo';
import Cadastro from './pages/cadastro/Cadastro';
import TrocarSenha from './pages/trocarSenha/TrocarSenha';
import EsqueciSenha from './pages/esqueciSenha/EsqueciSenha';
import PassoApasso from './elementos/passoApasso';
import Apresentacao from './pages/apresentacao/apresentacao';
import SucessoCadastro from './pages/cadastro/SucessoCadastro';
import Conversao from './pages/conversao/conversao';
import Integracao from './pages/integracao/Integracao';
import Assinatura from './pages/assinatura/Assinatura';
import IntegracaoMv from './pages/integracao/IntegracaoMV';
import LoginAdm from './pages/loginAdm/loginAdm';
import MainAdm from './pages/mainAdm/mainAdm';
import DashboardAdm from './pages/mainAdm/Pages/dashboard/DashboardAdm';
import UsuariosAdm from './pages/mainAdm/Pages/usuarios/UsuariosAdm';

const rotas = createBrowserRouter([
    {
        path: '/main',
        element: <Main />,
        children: [
            { path: ':id', element: <Conteudo /> },
            {
                path: '',
                element: <PassoApasso />,
            },

            { path: 'integracaoMv/:idRegistro', element: <IntegracaoMv /> },
        ],
    },
    {
        path: '/mainAdm',
        element: <MainAdm />,
        children: [
            { path: 'usuarios', element: <UsuariosAdm /> },
            {
                path: '',
                element: <DashboardAdm />,
            },
        ],
    },
    { path: '/cadastro', element: <Cadastro /> },
    { path: '/conversao', element: <Conversao /> },
    { path: '/sucessocadastro', element: <SucessoCadastro /> },
    { path: '/trocarSenha', element: <TrocarSenha /> },
    { path: '/esqueciSenha', element: <EsqueciSenha /> },
    { path: '/integracao', element: <Integracao /> },
    { path: '/assinatura', element: <Assinatura /> },
    { path: '/login', element: <Login /> },
    { path: '/loginAdm', element: <LoginAdm /> },
    { path: '/', element: <Apresentacao /> },
]);
export default rotas;
