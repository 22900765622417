import React from 'react';
import { Container, Itens, Item, ItensPrincipaisMenu, ItensInferioresMenu } from './MenuLateralAdm.styles';
import { Button, Drawer, Typography, useMediaQuery } from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import DashboardIcon from '@mui/icons-material/Dashboard';

function MenuLateralAdm() {
    const isSmallScreen = useMediaQuery('(max-width:800px)');
    const [openDrawer, setOpenDrawer] = React.useState(true);

    return (
        <>
            <Drawer
                sx={{ width: '300px' }}
                anchor={'left'}
                open={isSmallScreen ? openDrawer : true}
                onClose={() => setOpenDrawer(false)}
                variant={isSmallScreen ? 'temporary' : 'permanent'}
                PaperProps={{ sx: { borderRight: 'none' } }}
            >
                <Container>
                    <Itens>
                        <div>
                            <Typography variant="h6" textAlign={'center'}>
                                Menu Administrativo
                            </Typography>
                        </div>
                        <ItensPrincipaisMenu>
                            <Item to={'/mainAdm'}>
                                <DashboardIcon />
                                <Typography variant="body1">Dashboard</Typography>
                            </Item>
                            <Item to={'/mainAdm/usuarios'}>
                                <GroupIcon />
                                <Typography variant="body1">Usuários</Typography>
                            </Item>
                        </ItensPrincipaisMenu>
                        <ItensInferioresMenu>
                            <Button variant="contained" color="primary">
                                Gerenciar Assinatura
                            </Button>
                            <Item to={'/'}>Sair</Item>
                        </ItensInferioresMenu>
                    </Itens>
                </Container>
            </Drawer>
        </>
    );
}

export default MenuLateralAdm;
