import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 300px;
    max-width: 300px;
    height: 100%;
    background-color: #f4f4f4;
`;

export const Itens = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    margin: 10px;
    background-color: #fff;
    box-shadow: 0px 0px 10px 0px #0000002e;
    border-radius: 10px;
    padding: 10px;
    gap: 10px;
    overflow: hidden;
`;

export const Item = styled(Link)`
    display: flex;
    gap: 5px;
    justify-content: flex-start;
    align-items: center;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid rgba(79, 79, 79, 0);
    cursor: pointer;
    background-color: rgba(79, 79, 79, 0.125);
    color: rgb(79, 79, 79);
    transition-duration: 0.3s;
    box-shadow: rgba(79, 79, 79, 0.125) 0px 0px 5px 0px;
    user-select: none;
    text-decoration: none;
    font-weight: 600;

    &:hover {
        background-color: #f4f4f4;
    }
`;

export const ItensPrincipaisMenu = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    flex: 1;
    overflow-y: auto;

    > a {
        margin-bottom: 10px;
    }
`;

export const ItensInferioresMenu = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    > button {
        margin-bottom: 10px;
    }

    > a {
        display: flex;
        justify-content: center;
        font-weight: 600;
    }
`;
