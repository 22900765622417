import api from './api';

export async function getUsuario() {
    const response = await api.get(`/usuario`);
    return response.data;
}

export async function vinculaEspecialidadeENumConsultaMes(especialidadesIds, medicaConsultaMes) {
    const response = await api.post(`/usuario/vinclaEspecialidadeEMedicaConsultaMes`, {
        especialidadesIds,
        medicaConsultaMes: parseInt(medicaConsultaMes),
    });
    return response.data;
}

export async function getUsuariosCorp() {
    const response = await api.get(`/usuario/getUsuariosCop`);
    return response.data;
}
