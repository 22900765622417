import React, { useEffect, useMemo, useState } from 'react';
import { Container } from './ViewerResumoStream.styles';
import { TituloResumo } from './ViewerResumoStream.styles';
import { ContainerAssistentes } from './ViewerResumoStream.styles';
import { AssistenteStyle } from './ViewerResumoStream.styles';
import { geraResumoAssistenteEspecialistaStream, salvaResumoAsync } from '../../../services/resumo';
import { toast } from 'react-toastify';
import { Button } from '@mui/material';

function ViewerResumoStream({
    resumoSelecionado,
    atendimentoId,
    setRuning,
    setLoadingResumo,
    updateAtendimento,
    onCloseResumoStream,
}) {
    const [assistentesEspecialistas, setAssistentesEspecialistas] = useState([]);
    const [textoFinalizados, setTextoFinalizados] = useState([]);
    const [atendimentoCompleto, setAtendimentoCompleto] = useState(null);

    const isFinish = useMemo(() => {
        return textoFinalizados.length === assistentesEspecialistas.length && assistentesEspecialistas.length > 0;
    }, [textoFinalizados, assistentesEspecialistas]);

    useEffect(() => {
        setAssistentesEspecialistas([]);
        setTextoFinalizados([]);
    }, [atendimentoId]);

    useEffect(() => {
        const assistentesEspecialistas = resumoSelecionado?.assistentesPrompts;
        setAssistentesEspecialistas(assistentesEspecialistas || []);
        console.log(assistentesEspecialistas);
    }, [resumoSelecionado]);

    async function salvaResumoNoAtendimento() {
        try {
            setLoadingResumo(true);
            const retorno = await salvaResumoAsync(atendimentoId, resumoSelecionado.value);
            setAtendimentoCompleto(retorno);
            console.log('%c Log retorno gerado', retorno, 'color: green');
            toast.success('Resumo finalizado', { position: 'bottom-center' });
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingResumo(false);
        }
    }

    useEffect(() => {
        if (isFinish) {
            salvaResumoNoAtendimento();
        }
    }, [isFinish]);

    if (!resumoSelecionado?.name) return null;

    return (
        <Container>
            <TituloResumo>{resumoSelecionado.name}</TituloResumo>
            {!!atendimentoCompleto && (
                <Button
                    sx={{ color: '#fff', textTransform: 'none', position: 'absolute', right: '15px', top: '15px' }}
                    variant="contained"
                    onClick={() => {
                        setRuning(false);
                        updateAtendimento(atendimentoId);
                        onCloseResumoStream(atendimentoCompleto.texto.replaceAll('\n', '<br />'));
                    }}
                >
                    Fechar
                </Button>
            )}
            <ContainerAssistentes>
                {assistentesEspecialistas
                    .sort((a, b) => new Date(a.dataCriacao) - new Date(b.dataCriacao))
                    .map((assistente) => (
                        <Assistente
                            key={assistente.id}
                            atendimentoId={atendimentoId}
                            assistente={assistente}
                            updateListaFinalizados={setTextoFinalizados}
                        />
                    ))}
            </ContainerAssistentes>
        </Container>
    );
}

export default ViewerResumoStream;

const Assistente = ({ assistente, updateListaFinalizados, atendimentoId }) => {
    const [texto, setTexto] = useState('');
    const ref = React.useRef();

    useEffect(() => {
        init(assistente);
    }, [assistente]);

    function resizeTextarea(target) {
        target.style.height = 'auto';
        target.style.height = target.scrollHeight + 'px';
    }

    useEffect(() => {
        if (ref.current) resizeTextarea(ref.current);
    }, [texto]);

    function init(assistente) {
        function calllback(isFinish, texto) {
            if (isFinish) return updateListaFinalizados((prev) => [...prev, assistente.id]);
            setTexto((prev) => prev + texto);
        }

        function errorFn(error) {
            console.error(error);
            toast.error(`Error ao tentar gerar resumo do assistente: "${assistente.titulo}"`);

            setTexto(
                (prev) => `${prev} <p style="color:red">Erro ao tentar gerar resumo do assistente: "${assistente.titulo}"</p>`,
            );
        }

        geraResumoAssistenteEspecialistaStream(
            {
                idAtendimento: atendimentoId,
                idAssistentePrompt: assistente.id,
            },
            errorFn,
            calllback,
        );
    }

    return (
        <AssistenteStyle>
            <h3>{assistente.titulo}</h3>
            {texto.length == 0 ? (
                <article>Processando, aguarde...</article>
            ) : (
                <textarea
                    ref={ref}
                    onChange={(e) => resizeTextarea(e.target)}
                    value={texto.replaceAll('%%%', '\n')}
                    onFocus={(e) => resizeTextarea(e.target)}
                ></textarea>
            )}
        </AssistenteStyle>
    );
};
