import axios from 'axios';

export async function verificaIntegracao() {
    const response = await axios.get('http://localhost:3008/');
    return response;
}
export async function importarDadosMV(dados) {
    const response = await axios.post('http://localhost:3008/atendimento', dados);
    return response;
}
