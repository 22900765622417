import axios from 'axios';
import constantes from '../config/constantes';
import api from './api';
import { getTokenLocalStorage } from '../config/tokenManager';
import { NativeEventSource, EventSourcePolyfill } from 'event-source-polyfill';
import { XhrSource } from './eventsourcePost';
export async function createResumo(idAtendimento, tipo) {
    const resumo = await api.post('/Resumo', { AtendimentoId: idAtendimento, ResponseType: tipo });
    return resumo.data;
}

export async function getTiposResumos() {
    const resumos = await api.get('/Resumo/getTiposResumos');
    return resumos.data.map((resumo) => ({
        name: resumo.nome,
        value: resumo.responseType,
        description: resumo.descricao,
        protocolo: resumo.protocolo,
        id: resumo.id,
        assistentesPrompts: resumo?.assistentesPrompts,
    }));
}

export async function avaliaResumo(idResumo, avaliacao) {
    const resultAvaliacaoResumo = await api.post(`/resumo/${idResumo}/satisfacao/${avaliacao}`);
    return resultAvaliacaoResumo.data;
}

export const tiposDeRegistrosIAInteligente = [
    {
        name: 'Consulta Medica',
        value: 'consultamedica',
        description:
            'Registro detalhado de queixa, histórico médico, medicamentos, histórico familiar, social, hipóteses diagnósticas e conduta terapêutica.',
    },
    {
        name: 'Evolução Medica',
        value: 'evolucaomedica',
        description:
            'Registro da evolução do paciente, intercorrências, medicamentos em uso, resultados de exames e conduta terapêutica.',
    },
];

export function getChatCompletionStream({ idAtendimento, tipo }, errorFn, callback) {
    const endpoint =
        constantes.baseUrl + 'Resumo/getChatCompletionStream?AtendimentoId=' + idAtendimento + '&ResponseType=' + tipo;
    const token = getTokenLocalStorage();
    const headers = new Headers({
        Authorization: `Bearer ${token}`,
    });

    const requestOptions = {
        method: 'GET',
        headers: headers,
        redirect: 'follow',
    };

    fetch(endpoint, requestOptions)
        .then((response) => {
            const reader = response.body.getReader();
            reader.read().then(function processText({ done, value }) {
                // Result objects contain two properties:
                // done  - true if the stream has already given you all its data.
                // value - some data. Always undefined when done is true.
                if (done) {
                    console.log('Stream complete');
                    callback(true);
                    return;
                }

                // value for fetch streams is a Uint8Array
                const valorFormatado = new TextDecoder('utf8', { fatal: false, ignoreBOM: true }).decode(value);

                callback(false, valorFormatado);
                return reader.read().then(processText);
            });
        })

        .catch((error) => {
            errorFn(error);
            console.log('error', error);
        });
}

export function getChatCompletionStreamV2({ idAtendimento, tipo }, errorFn, callback) {
    const endpoint =
        constantes.baseUrl + 'Resumo/getChatCompletionStreamV2?AtendimentoId=' + idAtendimento + '&ResponseType=' + tipo;
    const token = getTokenLocalStorage();
    const headers = new Headers({
        Authorization: `Bearer ${token}`,
    });

    const requestOptions = {
        method: 'POST',
        headers: headers,
        redirect: 'follow',
    };

    fetch(endpoint, requestOptions)
        .then((response) => {
            const reader = response.body.getReader();
            reader.read().then(function processText({ done, value }) {
                // Result objects contain two properties:
                // done  - true if the stream has already given you all its data.
                // value - some data. Always undefined when done is true.
                if (done) {
                    console.log('Stream complete');
                    callback(true);
                    return;
                }

                // value for fetch streams is a Uint8Array
                const valorFormatado = new TextDecoder('utf8', { fatal: false, ignoreBOM: true }).decode(value);

                callback(false, valorFormatado);

                console.log('Valor Retornado Formatado: ', valorFormatado);
                return reader.read().then(processText);
            });
        })
        .catch((error) => {
            errorFn(error);
            console.log('error', error);
        });
}

export function getChatCompletionStreamV3({ idAtendimento, tipo }, errorFn, callback) {
    const endpoint =
        constantes.baseUrl + 'Resumo/getChatCompletionStreamV3?AtendimentoId=' + idAtendimento + '&ResponseType=' + tipo;
    const token = getTokenLocalStorage();
    const eventSourceInitDict = { headers: { Authorization: `Bearer ${token}` } };
    const eventSource = new EventSourcePolyfill(endpoint, eventSourceInitDict);

    eventSource.onmessage = (result) => {
        console.log('Result: ', result);
        if (result.type === 'message') {
            const mensagemFormatada = result.data.replace('Mensagem ', '');
            console.log(`Mensagem: ${mensagemFormatada}`);
            callback(false, mensagemFormatada);
        }
    };

    eventSource.onerror = (err) => {
        callback(true);
        console.error('EventSource error: ', err);
        eventSource.close();
    };
}

export function geraResumoStream({ idAtendimento, tipo }, errorFn, callback) {
    const endpoint = constantes.baseUrl + 'Resumo/GeraResumoStream?AtendimentoId=' + idAtendimento + '&ResponseType=' + tipo;
    const token = getTokenLocalStorage();
    const eventSourceInitDict = { headers: { Authorization: `Bearer ${token}` } };
    const eventSource = new EventSourcePolyfill(endpoint, eventSourceInitDict);

    eventSource.onmessage = (result) => {
        console.log('Result: ', result);
        if (result.type === 'message') {
            const mensagemFormatada = result.data.replace('Mensagem ', '');
            console.log(`Mensagem: ${mensagemFormatada}`);
            callback(false, mensagemFormatada);
        }
    };

    eventSource.addEventListener('close', (e) => {
        callback(true);
        console.log('EventSource close: ', e);
        eventSource.close();
    });

    eventSource.addEventListener('customError', (err) => {
        errorFn(err.data);
        console.error('EventSource customError: ', err);
        eventSource.close();
    });

    eventSource.onerror = (err) => {
        errorFn(err.data);
        console.error('EventSource error: ', err);
        eventSource.close();
    };
}

export function getProtocoloStream({ idAtendimento, tipo }, errorFn, callback) {
    const endpoint = constantes.baseUrl + 'Resumo/getProtocoloStream?AtendimentoId=' + idAtendimento + '&ResponseType=' + tipo;
    const token = getTokenLocalStorage();
    const eventSourceInitDict = { headers: { Authorization: `Bearer ${token}` } };
    const eventSource = new EventSourcePolyfill(endpoint, eventSourceInitDict);

    eventSource.onmessage = (result) => {
        console.log('Result: ', result);
        if (result.type === 'message') {
            const mensagemFormatada = result.data.replace('Mensagem ', '');
            console.log(`Mensagem: ${mensagemFormatada}`);
            callback(false, mensagemFormatada);
        }
    };

    eventSource.onerror = (err) => {
        callback(true);
        console.error('EventSource error: ', err);
        eventSource.close();
    };
}

export function getConversa(messages, errorFn, callback) {
    const endpoint = constantes.baseUrl + 'Resumo/getAssistentResponseStream';
    const token = getTokenLocalStorage();
    const eventSourceInitDict = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(messages),
    };
    const xs = XhrSource(endpoint, eventSourceInitDict);

    xs.addEventListener('error', (e) => {
        callback(true);
        console.error('EventSource error: ', e.reason);
    });

    xs.addEventListener('close', (e) => {
        callback(true);
    });

    xs.addEventListener('message', (result) => {
        // const msg = JSON.parse(e.data);
        console.log('Result: ', result);
        if (result.type === 'message') {
            const mensagemFormatada = result.data.replace('Mensagem ', '');
            console.log(`Mensagem: ${mensagemFormatada}`);
            callback(false, mensagemFormatada);
        }
        console.log(result);
    });
}

export async function postGeraResumoJson(idAtendimento) {
    const result = await api.post('Resumo/gerarResumoJson?idAtendimento=' + idAtendimento);
    return result.data;
}

export async function updateResumo({ id, json, texto, satisfaito, tipo, atendimentoId, httpCallDuration }) {
    const result = await api.put('Resumo', {
        Id: id,
        Texto: texto,
        Json: json,
        AtendimentoId: atendimentoId,
        HttpCallDuration: httpCallDuration,
        tipo: tipo,
        satisfaito: satisfaito,
    });
    return result.data;
}

export async function geraResumoAssistenteEspecialistaStream({ idAtendimento, idAssistentePrompt }, errorFn, callback) {
    const endpoint = `${constantes.baseUrl}resumo/GeraResumoAssistenteEspecialistaStream?AtendimentoId=${idAtendimento}&idAssistentePrompt=${idAssistentePrompt}`;
    const token = getTokenLocalStorage();
    const eventSourceInitDict = { headers: { Authorization: `Bearer ${token}` } };
    const eventSource = new EventSourcePolyfill(endpoint, eventSourceInitDict);

    eventSource.onmessage = (result) => {
        // console.log('Result: ', result);
        if (result.type === 'message') {
            callback(false, result.data);
        }
    };

    eventSource.addEventListener('close', (e) => {
        callback(true);
        console.log('EventSource close: ', e);
        eventSource.close();
    });

    eventSource.addEventListener('customError', (err) => {
        errorFn(err.data);
        console.error('EventSource customError: ', err);
        eventSource.close();
    });

    eventSource.onerror = (err) => {
        if (err.error) {
            errorFn(err.error);
        } else {
            callback(true);
        }
        console.error('EventSource error: ', err);
        eventSource.close();
    };
}

export async function salvaResumoAsync(idAtendimento, responseType) {
    const result = await api.post('Resumo/SalvaResumoAsync', {
        AtendimentoId: idAtendimento,
        ResponseType: responseType,
    });
    return result.data;
}
