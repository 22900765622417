import React, { useEffect, useState } from 'react';
import { Container } from './mainAdm.styles';
import { Outlet, useLocation } from 'react-router-dom';

import { useMediaQuery, Button, Menu } from '@mui/material';
import { MainAdmProvider, useMainAdm } from '../../contexts/MainAdmContext';
import MenuLateralAdm from './menuLateralAdm/MenuLateralAdm';
import styled from 'styled-components';

function MainAdm() {
    const location = useLocation();
    const isSmallScreen = useMediaQuery('(max-width:800px)');

    useEffect(() => {}, []);

    return (
        <MainAdmProvider>
            <Container isSmallScreen={isSmallScreen}>
                <MenuLateralAdm />
                <Outlet />
            </Container>
        </MainAdmProvider>
    );
}

export default MainAdm;
