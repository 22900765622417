import React, { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getTokenLocalStorage } from '../config/tokenManager';
import { getUsuario, getUsuariosCorp } from '../services/usuario';
import { toast } from 'react-toastify';

export const MainAdmContext = React.createContext(null);

export function useMainAdm() {
    return React.useContext(MainAdmContext);
}

export function MainAdmProvider({ children }) {
    const location = useLocation();
    const navigate = useNavigate();
    const [token, setToken] = React.useState(getTokenLocalStorage());
    const [usuario, setUsuario] = React.useState(null);
    const [usuariosCorp, setUsuariosCorp] = React.useState([]);

    const carregaUsuario = useCallback(async () => {
        if (token) {
            try {
                const usuario = await getUsuario();
                setUsuario(usuario);
            } catch (error) {
                console.error(error);
                if (error.response.status === 401) {
                    location.navigate('/login');
                }
            }
        } else {
            setUsuario(null);
            setToken(null);
        }
    }, [token]);

    const carregaUsuariosCorp = useCallback(async () => {
        if (token) {
            try {
                const usuariosCorp = await getUsuariosCorp();
                setUsuariosCorp(usuariosCorp);
            } catch (error) {
                console.error(error);
                toast.error('Erro ao carregar usuários corporativos');
            }
        } else {
            setUsuariosCorp([]);
        }
    }, [token]);

    useEffect(() => {
        if (!token) {
            toast.error('Você não está logado');
            navigate('/loginAdm', { replace: true, state: { from: location.pathname, ...location.state } });
        } else {
            carregaUsuario();
            carregaUsuariosCorp();
        }
    }, [token]);

    return (
        <MainAdmContext.Provider
            value={{
                token,
                carregaUsuario,
                usuario,
                usuariosCorp,
                setUsuariosCorp,
            }}
        >
            {children}
        </MainAdmContext.Provider>
    );
}
