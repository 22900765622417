import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useOutletContext, useParams, useSearchParams } from 'react-router-dom';
import { getAtendimentoById, updateAtendimento } from '../../../services/atendimento';
import {
    Box,
    FilledInput,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
    Tooltip,
    useTheme,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Divider,
    Typography,
    useMediaQuery,
} from '@mui/material';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import * as dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import relativeTime from 'dayjs/plugin/relativeTime';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import { Container, Header, Body, Input } from './Conteudo.Styles';
import LinhaDoTempo from './LinhaDoTempo';
import { useLiveQuery } from 'dexie-react-hooks';
import { db } from '../../../config/db';
import { toast } from 'react-toastify';
import { useMain } from '../../../contexts/MainContext';
import EditIcon from '@mui/icons-material/Edit';
import Protocolo from './Protocolo';
import { getTiposResumos } from '../../../services/resumo';
import { set } from 'react-ga';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { verificaIntegracao } from '../../../services/mvIntegracao';
dayjs.locale('pt-br');
dayjs.extend(relativeTime);

function Conteudo() {
    const { carregaAtendimentos } = useMain();
    const [isExternal] = useOutletContext();
    const theme = useTheme();
    const param = useParams();

    const [protocoloSelecionado, setProtocoloSelecionado] = useState({});
    const [searchParams] = useSearchParams();
    const [tiposResumo, setTiposResumo] = React.useState([]);
    const [atendimento, setAtendimento] = useState({
        nome: '',
        descricao: '',
        tipoAtendimento: {
            name: '',
            value: '',
            description: '',
        },
    });
    const [loading, setLoading] = useState(true);
    const [showHeader, setShowHeader] = useState(true);
    const isSmallScreen = useMediaQuery('(max-width:800px)');

    const audiosNaoEnviados = useLiveQuery(async () => {
        return await db.audios.where({ atendimentoId: param.id, status: 'local' }).toArray();
    }, [param]);

    useEffect(() => {
        init(param.id);
    }, [param.id]);

    async function init(idAtendimento) {
        try {
            setProtocoloSelecionado({});
            if (isExternal) {
                setShowHeader(false);
            }
            const [atendimento, allTiposResumo] = await Promise.all([getAtendimentoById(idAtendimento), getTiposResumos()]);
            setTiposResumo(allTiposResumo);

            if (atendimento?.resumos?.length) {
                setAtendimento({
                    ...atendimento,
                    tipoAtendimento: { name: atendimento?.resumos[0].tipo, value: '', description: '' },
                });
            } else {
                setAtendimento({
                    ...atendimento,
                    tipoAtendimento: {
                        name: '',
                        value: '',
                        description: '',
                    },
                });
            }

            try {
                const objAtendimento = {
                    tipo: 'atendimentoCriado',
                    data: atendimento,
                    final: false,
                };
                window.parent.postMessage(objAtendimento, '*');
            } catch (error) {
                console.log('Erro ao enviar audio para o iframe', error);
            }
        } catch (error) {
        } finally {
            setLoading(false);
        }
    }

    const atualizaAtendimento = useCallback(async () => {
        try {
            // setLoading(true);
            await updateAtendimento(atendimento.nome, atendimento.descricao, param.id);
            carregaAtendimentos();
            // toast.success('Atendimento atualizado com sucesso!');
        } catch (error) {
            console.error(error);
            toast.error('Erro ao atualizar informações do atendimento');
        } finally {
            // setLoading(false);
        }
    });

    return (
        <Container>
            <div className="divFilhoContainer">
                <Box
                    display={'flex'}
                    sx={{ cursor: 'pointer', userSelect: 'none' }}
                    title={showHeader ? 'Ocultar informações do atendimento' : 'Exibir informações do atendimento'}
                    onClick={() => setShowHeader(!showHeader)}
                >
                    <Divider sx={{ flex: 1, mr: 1 }}>
                        <Typography color={theme.palette.primary.dark} variant="caption">
                            Dados Consulta
                        </Typography>
                    </Divider>
                    <ModeEditIcon sx={{ fontSize: '18px', color: theme.palette.primary.dark }} />
                </Box>

                <Header>
                    {showHeader ? (
                        <>
                            <Box flex={1} display={'flex'} gap={'5px'} flexDirection={'column'}>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    label="Nome consulta"
                                    onKeyDown={(e) => e.key === 'Enter' && e.target.blur()}
                                    onBlur={atualizaAtendimento}
                                    onChange={(e) => setAtendimento({ ...atendimento, nome: e.target.value })}
                                    value={atendimento?.nome}
                                    defaultValue={atendimento?.nome}
                                    focused
                                    placeholder="Nome do atendimento"
                                    sx={{ margin: '8px 0px 10px 5px' }}
                                    id="nomeAtendimento"
                                    type={'text'}
                                    title="Clique para editar o nome do atendimento"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton aria-label="toggle password visibility" edge="end">
                                                    <EditIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <TextField
                                    id="descricaoAtendimento"
                                    size="small"
                                    variant="outlined"
                                    label="Descrição consulta"
                                    onKeyDown={(e) => e.key === 'Enter' && e.target.blur()}
                                    onBlur={atualizaAtendimento}
                                    onChange={(e) => setAtendimento({ ...atendimento, descricao: e.target.value })}
                                    value={atendimento?.descricao}
                                    defaultValue={atendimento?.descricao}
                                    focused
                                    placeholder="Descrição do atendimento"
                                    sx={{ margin: '8px 0px 10px 5px' }}
                                    type={'text'}
                                    title="Clique para editar a descrição do atendimento"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton aria-label="toggle password visibility" edge="end">
                                                    <EditIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <TextField
                                    id="tipoAtendimento"
                                    size="small"
                                    variant="outlined"
                                    label="Tipo da consulta"
                                    value={atendimento?.tipoAtendimento?.name}
                                    defaultValue={atendimento?.tipoAtendimento?.name ?? ''}
                                    focused
                                    InputProps={{ disabled: true }}
                                    InputLabelProps={{ disabled: true }}
                                    sx={{ margin: '8px 0px 10px 5px' }}
                                    type={'text'}
                                />
                            </Box>
                            <Box ml={2}>
                                {audiosNaoEnviados?.filter((i) => i.atendimentoId == atendimento.id).length != 0 && (
                                    <Tooltip title="Existem audios que não foram enviados ao servidor referente a este registro.">
                                        <IconButton>
                                            <CloudOffIcon color="error" />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </Box>
                        </>
                    ) : (
                        <Box width={'100%'}>
                            <Typography variant="subtitle1" sx={{ fontSize: '0.8rem' }}>
                                {`${atendimento?.nome} (${atendimento?.tipoAtendimento?.name})`}
                            </Typography>
                            <Typography variant="subtitle2" sx={{ fontSize: '0.7rem', color: theme.palette.text.secondary }}>
                                {atendimento?.descricao}
                            </Typography>
                        </Box>
                    )}
                </Header>
                <Body>
                    <LinhaDoTempo
                        setTipoAtendimento={setAtendimento}
                        atendimentoParam={atendimento}
                        tiposResumo={tiposResumo}
                        setProtocolo={setProtocoloSelecionado}
                        popupProtocoloIsOpen={protocoloSelecionado?.id}
                    />
                    {protocoloSelecionado?.id && (
                        <Protocolo
                            tiposResumo={tiposResumo}
                            close={() => setProtocoloSelecionado({})}
                            protocoloSelecionado={protocoloSelecionado}
                            setProtocoloSelecionado={setProtocoloSelecionado}
                        />
                    )}
                </Body>
            </div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={() => setLoading(false)}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    );
}

export default Conteudo;
